import { autoinject } from 'aurelia-framework';
import { Redirect, NavigationInstruction, RouterConfiguration, Router, Next } from 'aurelia-router'
import { PLATFORM } from 'aurelia-pal';
import { ApplicationState } from './application-state';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Netsuite } from './services/netsuite-service';

@autoinject()
export class App {

    configureRouter(config: RouterConfiguration) {
        config.title = 'AAA Flag & Banner';
        config.options.pushState = true;
        config.options.root = '/';
        config.addPipelineStep('authorize', {
            run(navigationInstruction: NavigationInstruction, next) {
                if (navigationInstruction.getAllInstructions().some(i => i.config.settings.auth)) { //.some(i => i.config.settings.roles.indexOf('admin') !== -1)) {
                    var auth_token = localStorage.getItem(_WEBPACK_IMPORT_.SESSION_TOKEN_KEY);
                    var isLoggedIn = (typeof auth_token !== "undefined" && auth_token !== null);
                    // console.log(navigationInstruction.getBaseUrl(), navigationInstruction.params.siteUrl, navigationInstruction);
                    var siteUrl = navigationInstruction.params.siteUrl;
                    if (!siteUrl) siteUrl = 'aaa';
                    if (!isLoggedIn) {
                        return next.cancel(new Redirect(siteUrl + '/login'));
                    }
                }
                return next();
            }
        });

        config.addPostRenderStep({
            run(navigationInstruction: NavigationInstruction, next: Next) {
                if (navigationInstruction.router.isNavigatingNew) {
                    window.scroll(0, 0);
                }
                return next();
            }
        });

        config.map([
            { route: '', redirect: 'aaa' },
            { route: 'aaa', name: 'root', moduleId: PLATFORM.moduleName('public-site') },
            //{ route: 'login',            name: 'login',          moduleId: 'login',          title: 'Login' },
            { route: ':siteUrl', name: 'branded-site', moduleId: PLATFORM.moduleName('branded-site'), settings: { auth: false } }
            // { route: [':siteUrl/c/:categoryUrl'], name: 'categories', moduleId: 'categories', title: 'Categories', nav: false },
            // { route: ':siteUrl/list', name: 'list', moduleId: 'product-list', title: 'Product List', nav: false },
            // { route: ':siteUrl/cart',    name: 'cart-view',   moduleId: 'cart-view', title:'Cart', nav: false }
        ]);
    }

    constructor(public appState: ApplicationState, public router: Router, public eventAggregator: EventAggregator, public netsuite: Netsuite) {
        // this.eventAggregator.subscribe('router:navigation:complete', payload => window.scrollTo(0, 0));
    }

}
