import { Router } from 'aurelia-router';
import { inject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { ApplicationState } from './application-state';
import { Product } from './product';
import { SiteConfig, Category, Item } from './models/site-config';

@inject(ApplicationState, EventAggregator, DialogService)
export class ProductList {

    public appState: ApplicationState;
    //private netsuite: Netsuite;
    public eventAggregator;
    public dialogService;
    public items;
    public heading: string;
    public showCategories: boolean;
    public category: Category;
    public categories: Map<string, Category>;
    public assetsRootUrl;

    constructor(appState, eventAggregator, dialogService) {
        this.eventAggregator = eventAggregator;
        this.dialogService = dialogService;
        this.appState = appState;
        //this.netsuite = netsuite;
        this.items = [];
        this.showCategories = true;
        this.categories = new Map<string, Category>();

        //this.subscribe();
    }

    activate(params, routeConfig) {
        return this.appState.siteConfig.promise.then(site => {
            //var cat = site.categories.get(params.categoryUrl);
            //console.log(cat);
            this.categories = site.categories;
            this.items = site.items;
            this.assetsRootUrl = site.assetsRootUrl;
        });
    }

    viewProduct(item) {
        //console.log('open product dialog', item);
        this.dialogService.open({ viewModel: Product, model: { item: item, assetsRootUrl: this.assetsRootUrl } });
    }
}
