import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Netsuite } from './services/netsuite-service';
import { SiteConfig, UserProfile, Category, Item } from './models/site-config';
import { Cart, CartLine, CartItem } from './models/cart';
import { DeferredPromise } from 'resources/deferred-promise';

export enum Environment {
    DEV = 'DEV',
    PROD = 'PROD',
}

@autoinject()
export class ApplicationState {

    version: string = _WEBPACK_IMPORT_.APP_VERSION; //'1.1.2';
    environment: Environment = _WEBPACK_IMPORT_.ENVIRONMENT; //'DEV';
    netsuiteEnvironment: string = _WEBPACK_IMPORT_.NETSUITE_ENVIRONMENT; //'sandbox';
    netsuiteAccount: string = _WEBPACK_IMPORT_.NETSUITE_ACCOUNT;
    netsuiteUrlPrefix: string = _WEBPACK_IMPORT_.NETSUITE_URL_PREFIX;

    resourceRootUrl = '/resources/';
    // resourceRootUrl = 'https://s3-us-west-1.amazonaws.com/aaaflag-portal/';
    _siteConfig: SiteConfig;
    siteConfig = new DeferredPromise<SiteConfig>();
    _cart: Cart;
    cart: Promise<Cart>;
    // itemCount: number = null;
    siteUrl: string;
    userProfile: UserProfile;
    profile: Promise<UserProfile>;

    sessionTokenKey: string = _WEBPACK_IMPORT_.SESSION_TOKEN_KEY;
    profileCacheKey: string = 'aaa_profile';
    siteCacheKey: string = 'aaa_siteconfig';

    constructor(public eventAggregator: EventAggregator, public netsuite: Netsuite) {
        if (this.environment === Environment.DEV) {
            this.profileCacheKey += '_dev';
            this.siteCacheKey += '_dev';
        }
    }


    getProfile(auth_token?): Promise<UserProfile> {
        if (this.profile) return this.profile;

        if (auth_token) {

            let profilePromise = this.netsuite.validateToken(auth_token).then((results): UserProfile => {
                if (results.success) {

                    localStorage.setItem(this.profileCacheKey, JSON.stringify(results));
                    let profile = this.initUser(results);
                    this.userProfile = profile;
                    if (!this.cart) this.getCart();
                    return profile;

                } else {

                    this.signOut()
                    // return Promise.reject('Invalid/Expired session token');
                    throw new Error('Invalid/Expired session token');

                }
            });

            let profileCache = localStorage.getItem(this.profileCacheKey);
            if (profileCache) {
                let profile = this.initUser(JSON.parse(profileCache));
                this.profile = Promise.resolve(profile);
                if (!this.cart) this.getCart();
                return this.profile;
            } else {
                this.profile = profilePromise;
                return profilePromise;
            }
        }
        return this.profile;
    }

    initUser(data) {
        var profile = new UserProfile(data.token);
        profile.firstName = data.firstname;
        profile.middleName = data.middlename;
        profile.lastName = data.lastname;
        profile.email = data.email;
        profile.addressbook = data.data.addresses;

        this.userProfile = profile;
        this.siteUrl = data.data.portalUrl;

        return profile;
    }

    login(email, password): Promise<UserProfile> {
        return this.profile = this.netsuite.authenticate(email, password).then((results) => {
            console.log('results', results);
            if (results.success) {
                localStorage.setItem(this.sessionTokenKey, results.token);
                localStorage.setItem("portal_site", results.data.portalUrl);

                var profile = new UserProfile(results.token);
                profile.firstName = results.firstname;
                profile.middleName = results.middlename;
                profile.lastName = results.lastname;
                profile.email = results.email;
                profile.addressbook = results.data.addresses;

                this.userProfile = profile;
                this.siteUrl = results.data.portalUrl;

                this.getCart();

                return Promise.resolve(profile);
            }
            return Promise.reject('Invalid username and/or password');
        });
    }

    signOut() {
        this.userProfile = null;
        this.profile = null;
        this._cart = null;
        localStorage.removeItem(this.sessionTokenKey);
        localStorage.removeItem(this.profileCacheKey);
        // this.router.navigateToRoute('branded-login');
        // window.location.href = '/';
        window.location.reload(true);
    }

    getCart() {
        if (this.cart) return this.cart;

        this.cart = this.netsuite.getCart()
            .then((result) => {
                return this.loadCart(result);
            });
        return this.cart;
    }

    async loadCart(_cart) {
        return this.siteConfig.promise.then(siteConfig => {
            // var itemCount = 0;
            var cart = new Cart();
            cart.fromJson(_cart, siteConfig);

            this._cart = cart;
            this.cart = Promise.resolve(cart);
            // this.itemCount = itemCount;
            console.log('cart', cart);
            return cart;
        })
    }

    getSiteConfig(siteUrl?: string): Promise<SiteConfig> {
        console.log('getSiteConfig', siteUrl, this.siteUrl);
        if (!siteUrl) {
            if (this.siteUrl) siteUrl = this.siteUrl;
            else this.siteUrl = siteUrl = 'aaa';
        }
        if (this.siteConfig.started && this.siteUrl == siteUrl) return this.siteConfig.promise;

        this.siteConfig.started = true;
        this.siteUrl = siteUrl;

        // let siteCache = localStorage.getItem(this.siteCacheKey + '_' + siteUrl);
        // if (siteCache) {
        //     let site = this.initSiteConfig(JSON.parse(siteCache));
        //     // return Promise.resolve(site);
        //     return this.siteConfig.promise;
        // }

        let siteConfigPromise = this.netsuite.getSiteConfig(siteUrl).then((result) => {
            // let profilePromise = this.netsuite.validateToken(auth_token).then((results): UserProfile => {
            // if (result.success) {
            localStorage.setItem(this.siteCacheKey + '_' + siteUrl, JSON.stringify(result));

            let site = this.initSiteConfig(result);
            return site;
            // }
            // // return Promise.reject('Invalid/Expired session token');
            // throw new Error('Invalid/Expired session token');
        });

        // else {
        //     this.profile = profilePromise;
        // }
        return this.siteConfig.promise = siteConfigPromise;
    }

    initSiteConfig(result): SiteConfig {
        // console.log('getSiteConfig', siteUrl, this.siteUrl);
        // if ((!siteUrl && !this.siteUrl) || (this.siteConfig.started && (!siteUrl || this.siteUrl == siteUrl))) return this.siteConfig.promise;

        // this.siteConfig.started = true;
        // this.siteUrl = siteUrl;
        // this.netsuite.getSiteConfig(siteUrl).then((result) => {
        console.log('getSiteConfig', result);
        var site = new SiteConfig(result.id, result.name, this.resourceRootUrl + result.assetFolder + '/');
        site.css = result.css;
        site.logoSm = site.assetsRootUrl + result.logo;
        site.footerImage1 = site.assetsRootUrl + result.footerImage;
        site.homeImage1 = site.assetsRootUrl + result.homeImage;
        site.url = result.url;
        this._siteConfig = site;
        this.siteUrl = result.url;

        if (site.css) {
            var node = document.createElement('style');
            node.innerHTML = site.css;
            document.body.appendChild(node);
        }

        if (result.items) result.items.forEach(function (_item) {
            var item = new Item(_item.id, _item.name, _item.smallImage, _item.largeImage, _item.artFile, _item.description, _item.price, _item.subitems, _item.hideSubitems);
            site.items.push(item);
            site.itemMap.set(item.id, item);
        });

        site.items.forEach(item => {
            if (item._subitems) {
                item.subitems = item._subitems.split(',').map(i => site.itemMap.get(i).clone());
            }
        });

        if (result.categories) result.categories.forEach(function (_cat) {
            var cat = new Category(_cat.id, _cat.name, _cat.url, _cat.thumbnail, _cat.parent);
            // cat.level = 1;
            let itemIds = _cat.items.split(',');
            cat.items = site.items.filter((item) => itemIds.indexOf(item.id) !== -1);
            // cat.children = ApplicationState.buildChildCategories(_cat, site, 2);
            // site.categories.set(cat.url, cat);
            site.categoriesNotNested.set(cat.url, cat);
            site.categoriesById.set(cat.id, cat);
        });

        const allCategories = Array.from(site.categoriesNotNested.values());
        // allCategories.filter(c => !c.parent).forEach(cat => {
        //     cat.children = ApplicationState.buildChildCategories(cat, allCategories, 2);
        //     site.categories.set(cat.url, cat);
        // });
        site.categories = ApplicationState.buildChildCategories(undefined, allCategories, 1);

        //self.siteConfig.assetsRootUrl = self.cdn + self.siteConfig.assetFolder + '/';
        //ApplicationState.siteConfig = result;
        console.log('siteConfig', site);
        //self.eventAggregator.publish('siteConfig', self._siteConfig);
        //self.cart = self.getCart(site);

        // this._siteConfig = site;
        //this.siteConfig.resolve(site);
        this.siteConfig.resolve(site);
        return site;
        // });

        // return this.siteConfig.promise;
    }

    static buildChildCategories(category: Category, allCategories: Category[], level: number) {
        var cats = new Map<string, Category>();
        allCategories.filter(cat => (!cat.parentId && level === 1) || (category && cat.parentId === category.id)).forEach(cat => {
            cat.level = level;
            cat.parent = category;
            if (cat.items.length) cat.showItemsAsList = true;
            cats.set(cat.url, cat);
            cat.children = ApplicationState.buildChildCategories(cat, allCategories, level + 1);
        });
        return cats;

        // for (var i = 0; i < category.subCategories.length; i++) {
        //     var _cat = category.subCategories[i];
        //     var cat = new Category(_cat.id, _cat.name, _cat.url, _cat.parent);
        //     //cat.description = _cat.custrecord_salesmenu_description;
        //     //cat.displayOrder = _cat.custrecord_salesmenu_display_order;
        //     cat.children = ApplicationState.buildChildCategories(_cat, site, level + 1);
        //     // cat.parent = category.id;
        //     cat.level = level;
        //     let itemIds = _cat.items.split(',');
        //     // cat.items = site.items.filter((item) => itemIds.indexOf(item.id) !== -1);
        //     cats.set(cat.url, cat);
        //     // site.categoriesNotNested.set(cat.url, cat);
        // }
        // return cats;
    }

    static updateSelectedCategory(categories: Map<string, Category>, currentCategoryId, level: Number = 1): boolean {
        var selectionFound = false;
        categories.forEach(function (category) {
            if (category.id == currentCategoryId) {
                category.state = 3;
                selectionFound = true;
                ApplicationState.updateSelectedCategory(category.children, currentCategoryId, category.level);
            }
            else {
                var childSelected = ApplicationState.updateSelectedCategory(category.children, currentCategoryId, category.level);
                if (childSelected) {
                    category.state = 2;
                    selectionFound = true;
                }
                else category.state = 0;
            }
        });
        return selectionFound;
    }
}
