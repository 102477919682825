import { autoinject } from 'aurelia-framework';
//import {EventAggregator} from 'aurelia-event-aggregator';
import { HttpClient, HttpResponseMessage } from "aurelia-http-client";
//import {HttpClient} from "aurelia-fetch-client";
//import {ApplicationState} from './application-state';
// import { SiteConfig, Category, Item, AddressModel } from '../models/site-config';
import { Cart, CartLine, CartItem } from '../models/cart';

@autoinject()
export class Netsuite {

    private netsuite = {
        externalUrlsSandbox: {
            portal: 'https://forms.netsuite.com/app/site/hosting/scriptlet.nl?script=188&deploy=1&compid=4095715_SB1&h=84a71131bc450c03913d',
            auth: 'https://forms.netsuite.com/app/site/hosting/scriptlet.nl?script=102&deploy=1&compid=4095715_SB1&h=5ed98ef95f8c878d47e5'
        },
        externalUrlsProd: {
            portal: 'https://forms.na1.netsuite.com/app/site/hosting/scriptlet.nl?script=173&deploy=1&compid=4095715&h=2deacd56defaa4e44489',
            auth: 'https://forms.na1.netsuite.com/app/site/hosting/scriptlet.nl?script=102&deploy=1&compid=4095715&h=b56cd41067382fcc6980'
        }
    };

    private sessionTokenKey = _WEBPACK_IMPORT_.SESSION_TOKEN_KEY;
    // private searches = [];
    // private fieldlookups = [];
    // private customScripts = [];
    private urls;

    constructor(private http: HttpClient) {
        if (_WEBPACK_IMPORT_.NETSUITE_ENVIRONMENT === 'sandbox') {
            this.urls = this.netsuite.externalUrlsSandbox;
        }
        else {
            this.urls = this.netsuite.externalUrlsProd;
        }
    }

    getCart() {
        return this.sendGet({
            url: this.urls['portal'],
            parameters: {
                aaa_action: 'cart-get'
            }
        });
    }

    addToCart(cartLine: CartLine) {
        console.log(cartLine);
        var line = cartLine.toObject();
        // var line = {
        //     id: cartLine.category.id,
        //     items: [],
        // };

        // if (cartLine.items && cartLine.items.length) {
        //     line.items = cartLine.items.map(i => i.toObject());
        //     // cartLine.items.forEach(function (ver) {
        //     //     let item = { id: ver.id, q: +ver.quantity };
        //     //     if (ver.subitems.length) {
        //     //         item.subitems = ver.subitems.map(i => i.toObject());
        //     //     }
        //     //     line.items.push(item);
        //     // });
        // }

        // var subitems = [];
        // cartLine.subItems.forEach(function (_subitem) {
        //     var subitem = {
        //         item: _subitem.item.id,
        //         qty: _subitem.quantity
        //     };
        //     subitems.push(subitem);
        // });
        // var subitemsJSON = '';
        // if (subitems.length) subitemsJSON = encodeURIComponent(JSON.stringify(subitems));

        return this.send({
            url: this.urls['portal'],
            parameters: {
                aaa_action: 'cart-add',
                aaa_item: line,
            }
        });
    }

    // editLineItem(cartLine: CartLine) {
    //     return this.send({
    //         url: this.urls['portal'],
    //         parameters: {
    //             aaa_action: 'editLineItem',
    //             // cust_line: cartLine.line,
    //             // cust_qty: cartLine.quantity
    //         }
    //     }).then(function (response) {
    //         // self.appState.itemCount += cartLine.quantity;
    //         // self.appState._cart.lines.push(cartLine);
    //         return response;
    //     });
    // }

    // removeLineItem(cartLine: CartLine) {
    //     return this.send({
    //         url: this.urls['portal'],
    //         parameters: {
    //             aaa_action: 'removeLineItem',
    //             // cust_line: cartLine.line,
    //             // cust_subitems: (cartLine.subItems.length) ? cartLine.subItems.map(i => i.line).join(',') : ''
    //         }
    //     }).then((response) => {
    //         // self.appState.itemCount += cartLine.quantity;
    //         // self.appState._cart.lines.push(cartLine);
    //         return response;
    //     });
    // }

    editLineItemVersion(cartLine: CartLine, ver: CartItem, sub?: CartItem, remove: boolean = false) {
        var line = cartLine.toObject();

        // var item = {
        //     id: cartLine.category.id,
        //     items: [],
        // }
        // if (cartLine.items && cartLine.items.length) {
        //     cartLine.items.forEach(function (ver) {
        //         item.items.push({ id: ver.item.id, q: ver.quantity });
        //     });
        // }

        let version;
        if (ver) version = { id: ver.item.id, q: ver.quantity };

        let subitem;
        if (sub) subitem = { id: sub.item.id, q: sub.quantity };

        return this.send({
            url: this.urls['portal'],
            parameters: {
                aaa_action: 'cart-edit-item',
                aaa_item: line,
                // aaa_line: cartLine.id,
                aaa_version: version,
                aaa_subitem: subitem,
                // aaa_qty: ver.quantity,
                aaa_remove: remove
            }
        });
    }

    orderSummary(shipAddressId: string) {
        // console.log(cart);
        return this.sendGet({
            url: this.urls['portal'],
            parameters: {
                aaa_action: 'order-summary',
                aaa_shipaddress: shipAddressId,
            }
        });
    }

    submitOrder(subtotal, shipAddressId: string) {
        // console.log(cart);
        return this.send({
            url: this.urls['portal'],
            parameters: {
                aaa_action: 'submit-order',
                aaa_shipaddress: shipAddressId,
                aaa_cart_subtotal: subtotal,
                // cust_id: cart.id,
                // cust_shipto: cart.shippingAddress.id,
                // cust_total: cart.total
            }
        });
    }

    authenticate(email, password) {
        return this.sendGet({
            url: this.urls['auth'],
            parameters: {
                aaa_action: 'auth',
                aaa_email: email,
                aaa_pass: password,
                aaa_appid: '3',
            }
        });
    }

    validateToken(authToken) {
        return this.sendGet({
            url: this.urls['auth'],
            parameters: {
                aaa_action: 'validate'
            }
        });
    }

    getSiteConfig(siteUrl) {
        return this.sendGet({
            url: this.urls['portal'],
            parameters: {
                aaa_action: 'site-config',
                aaa_siteurl: siteUrl
            }
        });
        // return this.http.get('/resources/cache-SiteConfig_living-spaces.txt').then(httpResponse => { return JSON.parse(httpResponse.response) });
        // this.customScripts.push({
        //     scriptUrl: this.urls['siteConfig'],
        //     parameters: {
        //         cust_site: siteId
        //     }
        // });
        // return this.send();
    }

    send(config: any) {
        let parameters: any = {};
        if (config.parameters) parameters = config.parameters;
        const token = localStorage.getItem(this.sessionTokenKey);
        if (token) parameters.aaa_token = token;

        return this.http.post(config.url, JSON.stringify(parameters))
            .then(this._handleHttpResponse);
    }

    sendGet(config: any) {
        let parameters: any = {};
        if (config.parameters) parameters = config.parameters;
        const token = localStorage.getItem(this.sessionTokenKey);
        if (token) parameters.aaa_token = token;

        let url: string = config.url;
        url += (url.includes('?')) ? '&' : '?';
        url += this._serialize(parameters);

        return this.http.jsonp(url)
            .then(this._handleHttpResponse);
    }

    _handleHttpResponse(response: HttpResponseMessage): any {
        if (response.statusCode === 200) {
            if (response.content.success === true) {
                // if (config.url === this.urls['portal'] && response.content.payload) return response.content.payload;
                if (response.content.payload) return response.content.payload;
                else return response.content;
            } else {
                throw response.content;
            }
        } else {
            throw response.statusText;
        }
    }

    _serialize(obj, prefix?) {
        var str = [];
        for (var p in obj) {
            if (obj.hasOwnProperty(p)) {
                var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
                str.push(typeof v == "object" ?
                    this._serialize(v, k) :
                    encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
        // console.log('serialize', obj, str, str.join("&"));
        return str.join("&");
    }

    // Changes XML to JSON
    // xmlToJson(xml) {
    //     // Create the return object
    //     var obj = {};

    //     if (xml.nodeType == 1) { // element
    //         // do attributes
    //         if (xml.attributes.length > 0) {
    //             obj["@attributes"] = {};
    //             for (var j = 0; j < xml.attributes.length; j++) {
    //                 var attribute = xml.attributes.item(j);
    //                 obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
    //             }
    //         }
    //     } else if (xml.nodeType == 3) { // text
    //         obj = xml.nodeValue;
    //     }

    //     // do children
    //     if (xml.hasChildNodes()) {
    //         for (var i = 0; i < xml.childNodes.length; i++) {
    //             var item = xml.childNodes.item(i);
    //             var nodeName = item.nodeName;
    //             if (typeof (obj[nodeName]) == "undefined") {
    //                 obj[nodeName] = this.xmlToJson(item);
    //             } else {
    //                 if (typeof (obj[nodeName].push) == "undefined") {
    //                     var old = obj[nodeName];
    //                     obj[nodeName] = [];
    //                     obj[nodeName].push(old);
    //                 }
    //                 obj[nodeName].push(this.xmlToJson(item));
    //             }
    //         }
    //     }
    //     return obj;
    // };

    /**
     * XML2jsobj v1.0
     * Converts XML to a JavaScript object
     * so it can be handled like a JSON message
     *
    * By Craig Buckler, @craigbuckler, http://optimalworks.net
    *
    * As featured on SitePoint.com:
    * http://www.sitepoint.com/xml-to-javascript-object/
    *
    * Please use as you wish at your own risk.
    */
    // XML2jsobj(node) {

    //     var data = {};

    //     // append a value
    //     function Add(name, value) {
    //         if (data[name]) {
    //             if (data[name].constructor != Array) {
    //                 data[name] = [data[name]];
    //             }
    //             data[name][data[name].length] = value;
    //         }
    //         else {
    //             data[name] = value;
    //         }
    //     };

    //     // element attributes
    //     var c, cn;
    //     if (node.attributes) {
    //         for (c = 0; cn = node.attributes[c]; c++) {
    //             Add(cn.name, cn.value);
    //         }
    //     }

    //     // child elements
    //     if (node.childNodes) {
    //         for (c = 0; cn = node.childNodes[c]; c++) {
    //             if (cn.nodeType == 1) {
    //                 if (cn.childNodes.length == 1 && cn.firstChild.nodeType == 3) {
    //                     // text value
    //                     Add(cn.nodeName, cn.firstChild.nodeValue);
    //                 }
    //                 else {
    //                     // sub-object
    //                     Add(cn.nodeName, this.XML2jsobj(cn));
    //                 }
    //             }
    //         }
    //     }

    //     return data;

    // }

};
