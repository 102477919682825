import { inject, bindable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Netsuite } from './services/netsuite-service';
import { SiteConfig, Item } from './models/site-config';

@inject(DialogController, Netsuite)
export class ProductImage {

    controller: DialogController;
    netsuite: Netsuite;
    item: Item;
    quantity: number;
    assetsRootUrl;

    constructor(controller, netsuite) {
        this.controller = controller;
        this.netsuite = netsuite;
        this.quantity = 1;

        controller.settings.lock = false;
    }

    activate(model) {
        console.log(model);
        this.item = model.item;
        this.assetsRootUrl = model.assetsRootUrl;
    }

    addToCart() {
        // this.netsuite.addToCart(this.item.id, this.quantity, 'Test Item')
        //     .then(function(result) {
        //         console.log('addToCart', result);
        //     });
    }
}