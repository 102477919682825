import { Router } from 'aurelia-router';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { ApplicationState } from './application-state';
import { Product } from './product';
import { SiteConfig, Category } from './models/site-config';

@autoinject()
export class BrandedHome {

    categories: Map<string, Category>;
    assetsRootUrl;
    homeImage1: string;

    constructor(public appState: ApplicationState, public eventAggregator: EventAggregator, public dialogService: DialogService) {
        // console.log('CONSTRUCTOR home');
    }

    activate(params, routeConfig) {
        // console.log('ACTIVATE', routeConfig);
        return this.appState.getSiteConfig().then((site) => {
            this.categories = site.categories;
            this.assetsRootUrl = site.assetsRootUrl;
            this.homeImage1 = site.homeImage1;
        });
    }
}

