
export class SiteConfig {
    categories = new Map<string, Category>();
    categoriesById = new Map<string, Category>();
    categoriesNotNested = new Map<string, Category>();
    items: Item[] = [];
    itemMap = new Map<string, Item>();
    // itemVersions: ItemVersion[] = [];
    // itemVersionMap = new Map<string, ItemVersion>();
    // hardware: InventoryItem[] = [];
    logoSm: string;
    footerImage1: string;
    homeImage1: string;
    css: string;
    url: string;
    // itemCount: number;

    constructor(public id, public name: string, public assetsRootUrl: string) {
    }

}

export class UserProfile {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    addressbook: AddressModel[] = [];

    constructor(public token: string) {
    }
}

export class AddressModel {
    defaultshipping: boolean;
    defaultbilling: boolean;

    constructor(public id: string, public label: string, public addrtext: string) {
    }
}

// export class Item {
//     public smallImage: string;
//     // public largeImage: string;
//     public url: string;
//     public versions: ItemVersion[] = [];
//     public hardware: InventoryItem[] = [];
//     // public order: number;

//     constructor(public id: string, public name?: string) {
//         if (!this.url) this.url = this.name.toLowerCase().replace(/ /g, '-') + '-' + this.id;
//     }
// }

// export class InventoryItem {
//     public categoryIds: string[] = [];
//     public description: string;
//     public detail: string;
//     public itemtype: string;
//     public smallImage: string;
//     public largeImage: string;
//     public url: string;
//     public price: number;
//     public priceFormatted: string;
//     public versions: ItemVersion[] = [];
//     public hardware: InventoryItem[] = [];
//     public order: number;

//     constructor(public type: number, public id: string, public name?: string, public itemid?: string, public upc?: string) {
//         if (!this.url) this.url = this.name.toLowerCase().replace(/ /g, '-') + '-' + this.id;
//     }
// }

export class Item {
    quantity: number = 1;
    selected: boolean = true;
    includeHardware: boolean = true;
    order: number = 99;
    price: number;
    priceFormatted: string;
    subitems: Item[] = [];

    constructor(public id: string, public name: string, public smallImage: string, public largeImage: string, public artfile: string, public description: string, public _price: string, public _subitems: string, public hideSubitems = false) {
        this.price = Number(_price);
        this.priceFormatted = _price;
    }

    clone() {
        let item = new Item(this.id, this.name, this.smallImage, this.largeImage, this.artfile, this.description, this._price, this._subitems);
        item.order = this.order;
        return item;
    }
}

export class Category {
    description: string;
    displayOrder: number;
    state: number = 1;
    parent: Category;
    children: Map<string, Category> = new Map<string, Category>();
    itemid: string;
    level: number;
    items: Item[] = [];
    showItemsAsList: boolean;

    constructor(public id: string, public name: string, public url: string, public thumbnail: string, public parentId: string) {
        if (!this.url) this.url = this.name.toLowerCase().replace(/ /g, '-') + '-' + this.id;
    }
}
