import { Aurelia, autoinject, bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from 'application-state';
// import { User } from 'models/user';
import { Router, RouteConfig } from 'aurelia-router';
// import { UserService } from 'services/user-service';

enum RegistrationResponseErrors {
    MISSING_PARAMS = 'test',
    NO_CONTACT = 'NO_CONTACT',
    VERIFIED_PROFILE_EXISTS = 'VERIFIED_PROFILE_EXISTS',
    UNVERIFIED_PROFILE_EXISTS = 'UNVERIFIED_PROFILE_EXISTS',
    SAVE_ERROR = 'SAVE_ERROR',
    HASH_MISMATCH = 'HASH_MISMATCH',
    NO_PROFILE_HASH = 'NO_PROFILE_HASH',
    NO_PROFILE_EXISTS = 'NO_PROFILE_EXISTS',
    UNKNOWN = 'UNKNOWN'
}

enum RegistrationRouteActions {
    REGISTER = 'register',
    CONFIRM_EMAIL = 'confirm-registration',
    REQUEST_RESET = 'request-reset',
    CONFIRM_RESET = 'confirm-reset'
}

enum RegistrationSteps {
    ONE,
    TWO
}

@autoinject
export class Register {

    // Core Models

    // Configuration
    private readonly tokenCacheKey: string = _WEBPACK_IMPORT_.SESSION_TOKEN_KEY;
    // private ADAL: adal.AuthenticationContext;
    // private userService: UserService
    public registrationRouteActions: typeof RegistrationRouteActions = RegistrationRouteActions;

    // State
    private routeParams: any;
    public processing: boolean = false;
    public errorMsg: string;
    public successMsg: string;

    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public password: string = '';
    public confirmPassword: string = '';

    public showResetPassword: boolean = false;
    public showConfirmationResend: boolean = false;
    public registrationRequestSuccess: boolean = false;

    public currentAction: RegistrationRouteActions = RegistrationRouteActions.REGISTER;
    public currentReigistraitonStep: RegistrationSteps = RegistrationSteps.ONE
    public redirectAfterLogin: string;
    public isLoggedIn: boolean = false;

    // HTML Elms
    public emailInput: HTMLInputElement;

    constructor(
        private appState: ApplicationState,
        private router: Router,
        private aurelia: Aurelia
    ) {
        // this.userService = appState.userService;
    }

    /********************************
     * LIFECYCLE METHODS
     ********************************/

    activate(params, routeConfig: RouteConfig) {

        // console.log('Register - activate', params, routeConfig);
        this.routeParams = params || {};
        this.redirectAfterLogin = (params.redirect) ? params.redirect : '/';
        if (params.email) this.email = params.email;

        if (routeConfig.settings.root === 'app') this.isLoggedIn = true;

        this.resetMessages();

        switch (this.routeParams.action) {
            case RegistrationRouteActions.CONFIRM_EMAIL:
                if (!this.routeParams.id || !this.routeParams.hash) {
                    this.currentAction = RegistrationRouteActions.REGISTER;
                } else {
                    this.currentAction = RegistrationRouteActions.CONFIRM_EMAIL;
                    this.confirmRegistration(this.routeParams.id, this.routeParams.hash);
                }
                break;
            case RegistrationRouteActions.REQUEST_RESET:
                this.currentAction = RegistrationRouteActions.REQUEST_RESET;
                this.email = this.routeParams.email;
                break;
            case RegistrationRouteActions.CONFIRM_RESET:
                if (!this.routeParams.id || !this.routeParams.hash) {
                    this.currentAction = RegistrationRouteActions.REGISTER;
                } else {
                    this.currentAction = RegistrationRouteActions.CONFIRM_RESET;
                }
                break;
            case RegistrationRouteActions.REGISTER:
            default:
                this.currentAction = RegistrationRouteActions.REGISTER;
                break;
        }

    }



    // attached() {

    //     if (this.routeParams.token) {
    //         this.handleRouteToken();
    //     } else {
    //         const token = this.getCachedToken();
    //         if (typeof token !== 'undefined') this.handleCachedToken(token);
    //     }

    //     console.warn('attached')

    // }

    /********************************
     * GETTERS & SETTERS
     ********************************/

    @computedFrom('appState.loadedUser.id')
    public get activeUser() {
        return //this.appState.loadedUser;
    }

    @computedFrom('appState.isMobile')
    public get isMobile(): boolean {
        return //this.appState.isMobile;
    }

    @computedFrom('firstName')
    public get firstNameValid(): boolean {
        return (this.firstName && this.firstName.length > 0);
    }

    @computedFrom('lastName')
    public get lastNameValid(): boolean {
        return (this.lastName && this.lastName.length > 0);
    }

    @computedFrom('email', 'emailInput')
    public get emailValid(): boolean {
        return (this.email && this.email.length > 0 && (this.emailInput && this.emailInput.validity.valid) && !this.email.trim().toLowerCase().match('@aaaflag.com'));
    }

    @computedFrom('password')
    public get passwordValid(): boolean {
        return (this.password && this.password.length > 7);
    }

    @computedFrom('confirmPassword', 'passwordValid')
    public get confirmPasswordValid(): boolean {
        return this.passwordValid && this.password === this.confirmPassword;
    }

    @computedFrom('firstNameValid', 'lastNameValid', 'emailValid', 'passwordValid', 'confirmPasswordValid')
    public get formValid(): boolean {
        return (
            this.firstNameValid &&
            this.lastNameValid &&
            this.emailValid &&
            this.passwordValid &&
            this.confirmPasswordValid
        );
    }

    @computedFrom() // So the component doesn't use dirty-checking. Cant use ' & one-time' in view bc it's in a comparison
    public get RegistrationSteps(): typeof RegistrationSteps {
        return RegistrationSteps
    }


    /********************************
     * COMPONENT FUNCTIONALITY
     ********************************/

    /**
    * Writes an auth token to local storage
    *
    * @param token The auth token to write
    */
    // private setCachedToken(token: string) {
    //     localStorage.setItem(this.tokenCacheKey, token);
    // }

    // private getCachedToken(): string {
    //     const token = localStorage.getItem(this.tokenCacheKey);
    //     return (token) ? token : undefined          // Ensures we don't return `null`
    // }

    // private clearCachedToken() {
    //     localStorage.removeItem(this.tokenCacheKey);
    // }

    // private handleRouteToken() {

    //     if (!this.routeParams || !this.routeParams.token) return;

    //     const token = this.routeParams.token;

    //     this.processing = true;

    //     this.setCachedToken(token);

    //     this.userService
    //         .validateToken()
    //         .then((user) => {
    //             this.router.navigateToRoute('app');
    //             // window.location.reload();
    //         })
    //         .catch(err => {
    //             console.error('[Register] - handleRouteHasToken() - Error validating token.', err);
    //             this.clearCachedToken();
    //             this.processing = false;
    //             this.errorMsg = 'Error logging in, please refresh the page to try again';
    //         });

    // }

    /**
     * Handles the case when a token is found in the cache
     *
     * @param token token as found in cache
     */
    // private handleCachedToken(token: string) {

    //     if (typeof token === 'undefined' || token === null) return;

    //     if (token === 'adal') return this.loginUsingCachedAdalSession();

    //     this.processing = true;

    //     this.userService
    //         .getUser()
    //         .then(user => {
    //             this.router.navigateToRoute('app');
    //         })
    //         .catch(err => {
    //             console.error('[Register] - Attached().getUser() - Error getting user.', err);
    //             this.clearCachedToken();
    //             this.processing = false;
    //             this.errorMsg = 'Error logging in, please refresh the page to try again';

    //         });


    // }

    // private loginUsingCachedAdalSession() {

    //     if (!this.ADAL) {
    //         if (typeof (window as any).initADAL === 'function') (window as any).initADAL();
    //         else return console.error('[Register] - Cannot initalize ADAL service.')
    //     }

    //     const cachedUser = this.ADAL.getCachedUser();

    //     if (!cachedUser) return console.error('[Register] - Could not get cached user from ADAL service.')

    //     this.processing = true;

    //     this.userService
    //         .adalAuth(cachedUser.userName, '')
    //         .then(user => {
    //             this.router.navigateToRoute('app');
    //         })
    //         .catch(err => {
    //             this.processing = false;
    //             this.clearCachedToken();
    //             this.errorMsg = 'Error logging in, please refresh the page to try again';
    //         });

    // }

    private clearForm() {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.password = '';
        this.confirmPassword = '';
    }

    private resetMessages() {
        console.warn('resetting messages')
        this.errorMsg = '';
        this.successMsg = '';

        this.showResetPassword = false;
        this.showConfirmationResend = false;
    }

    /**
     * Sets user-friendly error message based on error code returned by server
     *
     * @param errCode The error code as returned by the server.
     * @return The user-friendly message that was set
     */
    private setRegistrationError(errCode?: string): string {

        if (typeof errCode !== 'string' || !errCode) return;

        switch (errCode) {
            case RegistrationResponseErrors.MISSING_PARAMS:
                return this.errorMsg = `Required inputs were not provided, please double check all fields are filled out.`;
            case RegistrationResponseErrors.NO_CONTACT:
                return this.errorMsg = `No contact found matching email <b>${this.email}</b>. Please check the email entered, or contact your sales rep.`;
            case RegistrationResponseErrors.VERIFIED_PROFILE_EXISTS:
                this.showResetPassword = true;
                return this.errorMsg = `Account matching email <b>${this.email}</b> has already been created.`;
            case RegistrationResponseErrors.UNVERIFIED_PROFILE_EXISTS:
                this.showConfirmationResend = true;
                return this.errorMsg = `Account matching email <b>${this.email}</b> has already been created.`;
            case RegistrationResponseErrors.SAVE_ERROR:
                return this.errorMsg = `There was an error saving your records. Please refresh the page and try again. If the issue persists, please reach out to your sales rep.`;
            case RegistrationResponseErrors.HASH_MISMATCH:
                return this.errorMsg = `Invalid session. Please make sure you used the most recent email you received to visit this link.`;
            case RegistrationResponseErrors.NO_PROFILE_HASH:
                return this.errorMsg = `Invalid request. Please make sure you're not using an old link to complete this action.`;
            case RegistrationResponseErrors.NO_PROFILE_EXISTS:
                return this.errorMsg = `No profile found. Please double check the email entered.`;
            case RegistrationResponseErrors.UNKNOWN:
                return this.errorMsg = `Unknown error ocurred. Please refresh the page and try again. If the issue persists, please reach out to your sales rep.`;
            default:
                console.warn(`[Register] - setRegistrationError() - Unknown errCode: ${errCode}`);
                return this.errorMsg = `Unknown error ocurred (${errCode}). Please refresh the page and try again. If the issue persists, please reach out to your sales rep.`;

        }
    }

    private gotoRegStep(step: RegistrationSteps) {

        if (typeof RegistrationSteps[step] !== 'undefined')
            this.currentReigistraitonStep = step;

    }

    public gotoNextRegStep() {
        this.gotoRegStep(this.currentReigistraitonStep + 1);
    }

    public gotoPreviousRegStep() {
        this.errorMsg = '';
        this.gotoRegStep(this.currentReigistraitonStep - 1);
    }

    public gotoPasswordReset() {
        this.resetMessages();
        this.currentAction = RegistrationRouteActions.REQUEST_RESET;
    }

    public requestRegistration() {

        // if (!this.formValid && !this.processing) return;

        // this.resetMessages();
        // this.processing = true;

        // const request: RegistrationRequest = {
        //     firstName: this.firstName,
        //     lastName: this.lastName,
        //     email: this.email,
        //     password: this.password
        // }

        // this.userService
        //     .register(request)
        //     .then(msg => {
        //         console.warn('[Register] - requestRegistration() - Success!');
        //         this.successMsg = `<b>Success!</b> You'll need to confirm your email address before accessing your account. We've sent a confirmation email to <b>${request.email}</b>.`;
        //         this.registrationRequestSuccess = true;
        //         this.processing = false;
        //     })
        //     .catch((err: Error) => {
        //         console.error('[Register] - requestRegistration() - Error: ', err);
        //         if (err && err.message) this.setRegistrationError(err.message);
        //         else this.setRegistrationError();
        //         this.processing = false;
        //     });

    }

    public resetPassword() {

        // if (!this.emailValid && !this.processing) return;

        // this.resetMessages();
        // this.processing = true;

        // this.userService
        //     .resetPassword(this.email)
        //     .then(msg => {
        //         console.warn('[Register] - resetPassword() - Success!');
        //         this.successMsg = `<b>Success!</b> We've sent a reset password confirmation email to <b>${this.email}</b>. Please make sure to check your spam folder.`;
        //         this.processing = false;
        //     })
        //     .catch((err: Error) => {
        //         console.error('[Register] - resetPassword() - Error: ', err);
        //         if (err && err.message) this.setRegistrationError(err.message);
        //         else this.setRegistrationError();
        //         this.processing = false;
        //     });

    }

    public resendConfirmation() {

        // if (!this.emailValid && !this.processing) return;

        // this.resetMessages();
        // this.processing = true;

        // this.userService
        //     .resendConfirmation(this.email)
        //     .then(msg => {
        //         console.warn('[Register] - resendConfirmation() - Success!');
        //         this.successMsg = `<b>Success!</b> We've resent a confirmation email to <b>${this.email}</b>. Please make sure to check your spam folder.`;
        //         this.processing = false;
        //     })
        //     .catch((err: Error) => {
        //         console.error('[Register] - resendConfirmation() - Error: ', err);
        //         if (err && err.message) this.setRegistrationError(err.message);
        //         else this.setRegistrationError();
        //         this.processing = false;
        //     });

    }

    private confirmRegistration(id: string, hash: string) {

        if (
            !id || typeof id !== 'string' ||
            !hash || typeof hash !== 'string'
        ) {
            console.error('[Register] - confirmRegistration() - Invalid argument', {
                id: id,
                hash: hash
            });
            return;
        }

        if (this.processing) return;

        this.resetMessages();
        this.processing = true;

        // this.userService
        //     .confirmRegistration(id, hash)
        //     .then(userDefinition => {
        //         this.successMsg = `<b>Success!</b><br>Your account has been confirmed. Redirecting you to the home page...`;
        //         this.processing = false;

        //         this.userService.handleSuccessfulAuth(userDefinition);

        //         setTimeout(() => {
        //             if (this.isLoggedIn) {
        //                 this.router.navigate(this.redirectAfterLogin);
        //                 window.location.reload();
        //             } else {
        //                 this.router.navigate(this.redirectAfterLogin, { replace: true, trigger: false });
        //                 this.router.reset();
        //                 this.aurelia.setRoot('app');
        //             }
        //         }, 3000);

        //     })
        //     .catch(err => {
        //         console.error('[Register] - confirmRegistration() - Error: ', err);
        //         if (err && err.message) this.setRegistrationError(err.message);
        //         else this.setRegistrationError();
        //         console.warn('DONE SETTING ERROR', this.errorMsg)
        //         this.processing = false;
        //     });

    }

    public confirmPasswordReset() {

        if (
            !this.routeParams ||
            !this.routeParams.id || typeof this.routeParams.id !== 'string' ||
            !this.routeParams.hash || typeof this.routeParams.hash !== 'string'
        ) {
            console.error('[Register] - confirmPasswordReset() - Invalid route parameter', this.routeParams);
            return;
        }


        if (!this.passwordValid || this.processing) return;

        this.resetMessages();
        this.processing = true;

        const id = this.routeParams.id;
        const hash = this.routeParams.hash;
        const password = this.password;

        // this.userService
        //     .confirmPasswordReset(id, hash, password)
        //     .then(msg => {
        //         this.successMsg = `<b>Success!</b> Your password has been reset.`;
        //         this.processing = false;
        //     })
        //     .catch(err => {
        //         console.error('[Register] - confirmRegistration() - Error: ', err);
        //         if (err && err.message) this.setRegistrationError(err.message);
        //         else this.setRegistrationError();
        //         this.processing = false;
        //     });

    }

}
