import { Router } from 'aurelia-router';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { ApplicationState } from './application-state';
// import { SiteConfig, UserProfile, Category, Item } from './models/site-config';
import { Netsuite } from './services/netsuite-service';

@autoinject
export class Login {

    @bindable emailInput: HTMLInputElement;
    //@bindable submitButton: HTMLButtonElement;

    assetsRootUrl;
    homeImage1: string;
    email: string = '';
    password: string = '';
    processing: boolean;
    errorMsg: string = '';

    constructor(
        public netsuite: Netsuite,
        public appState: ApplicationState,
        public router: Router,
        public eventAggregator: EventAggregator,
        public dialogService: DialogService
    ) {
        // this.appState.siteConfig.then(function(site) {
        //     self.assetsRootUrl = site.assetsRootUrl;
        //     self.homeImage1 = site.homeImage1;
        // });
    }

    activate(params) {

        if (params.token) {

            localStorage.setItem(_WEBPACK_IMPORT_.SESSION_TOKEN_KEY, params.token);
            return this.appState.getProfile(params.token).then((profile) => {

                return this.appState.getSiteConfig(this.appState.siteUrl).then(siteConfig => {
                    this.router.navigateToRoute('home');
                });

            }).catch(
                (error) => {
                    console.error('Invalid token', error);
                    // this.appState.signOut();
                    // this.router.navigateToRoute('aaa');
                }
            );

        }

    }

    attached() {
        this.emailInput.focus();
        // this.appState.getSiteConfig().then((site) => {
        //     this.homeImage1 = site.homeImage1;
        //     // this.clientLogo = site.logoSm;
        //     console.log('logo should be shown!', site);
        // });
    }

    // activate(params, routeConfig) {
    //     var self = this;
    //     return this.appState.siteConfig.then(function(site) {
    //         self.categories = site.categories;
    //         self.assetsRootUrl = site.assetsRootUrl;
    //     });
    // }

    login() {
        this.errorMsg = '';
        this.processing = true;
        this.appState.login(this.email, this.password).then((profile) => {
            this.appState.getSiteConfig(this.appState.siteUrl);
            // this.appState.getCart();
            // if (self.appState.siteUrl && self.appState.siteUrl == results.session.portalUrl) {
            //     self.router.navigateToRoute('branded-home');
            // }
            // else {
            //     self.router.navigateToRoute('branded-site', { siteUrl: results.session.portalUrl });
            // }
            this.router.navigateToRoute('branded-site', { siteUrl: this.appState.siteUrl });
        }).catch(() => {
            this.processing = false;
            this.errorMsg = 'Invalid username and/or password';
        });
        // return this.auth.login(this.email, this.password)
        // .then(response=>{
        //     console.log("success logged " + response);
        // })
        // .catch(err=>{
        //     console.log("login failure");
        // });
    };

}
