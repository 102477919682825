import { Router } from 'aurelia-router';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { ApplicationState } from './application-state';
import { Product } from './product';
import { SiteConfig, UserProfile, AddressModel, Category, Item } from './models/site-config';
import { Cart, CartLine } from './models/cart';
import { Netsuite } from './services/netsuite-service';
import { ProductImage } from './product-image';

@autoinject()
export class Checkout {

    assetsRootUrl: string;
    cart: Cart;
    shippingAddress: AddressModel;

    view;
    processing: boolean;
    processingMessage: string;
    pageHeader: string;

    subtotal;
    taxtotal;
    total;
    taxrate;

    orderComplete: boolean;
    orderNumber;

    constructor(
        public netsuite: Netsuite,
        public appState: ApplicationState,
        public router: Router,
        public eventAggregator: EventAggregator,
        public dialogService: DialogService
    ) { }

    activate(params, routeConfig) {
        //console.log('params', params);

        this.view = 'shipping';
        this.pageHeader = 'Shipping Address';

        if (!this.appState.userProfile || !this.appState._cart) {
            this.processingMessage = 'Retrieving cart and addresses...';
            this.processing = true;
        }

        this.appState.getSiteConfig(this.appState.siteUrl).then((site) => {
            this.assetsRootUrl = site.assetsRootUrl;
        });
        return this.appState.getProfile().then((profile) => {
            console.log(profile);
            profile.addressbook.forEach((address) => {
                if (address.defaultshipping) {
                    this.shippingAddress = address;
                }
            });

            this.appState.getCart().then((cart) => {
                console.log(cart);
                if (cart.lines.length == 0) this.router.navigateToRoute('cart');
                if (this.shippingAddress) cart.shippingAddress = this.shippingAddress;
                this.cart = cart;
                this.processing = false;
            });
        });

    }

    nextStep(currentStep: string) {
        window.scrollTo(0, 0);
        if (currentStep == 'shipping') {
            this.view = 'order-details';
            this.pageHeader = 'Review Order';
            this.processingMessage = 'Preparing Summary...';
            this.processing = true;
            return this.netsuite.orderSummary(this.cart.shippingAddress.id).then(async (result) => {
                console.log('orderSummary', result);
                this.processing = false;
                this.subtotal = result.subtotal;
                this.taxtotal = result.taxtotal;
                this.total = result.total;
                this.taxrate = result.taxrate;
                // if (result.success) {
                //     this.cart = await this.appState.loadCart(result.cart);
                // }
            });
        }
    }

    selectShippingAddress(address) {
        this.shippingAddress = address;
        this.cart.shippingAddress = address;
    }

    submitOrder() {
        console.log(this.cart);
        this.pageHeader = '';
        this.processingMessage = 'Completing Your Order...';
        this.processing = true;

        this.netsuite.submitOrder(this.subtotal, this.cart.shippingAddress.id).then(result => {

            this.pageHeader = 'Order Submitted';
            this.orderComplete = true;
            this.processing = false;
            // this.view = 'order-details';
            this.orderNumber = result.tranid;
            this.appState._cart = new Cart();
            this.appState.cart = undefined;
            // this.appState._siteConfig.itemCount = 0;

        }).catch(error => {
            this.processing = false;
            if (error.message) alert(error.message);
        });
    }

    viewProductImage(item) {
        //console.log('open product dialog', ver);
        this.dialogService.open({
            viewModel: ProductImage, //'product-image',
            model: {
                item: item,
                assetsRootUrl: this.assetsRootUrl
            },
            lock: false
        });
    }

}

