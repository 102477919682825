import { autoinject } from 'aurelia-framework';
import { Redirect, RouterConfiguration, Router } from 'aurelia-router'
import { PLATFORM } from 'aurelia-pal';
import { ApplicationState } from './application-state';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class PublicSite {

    configureRouter(config: RouterConfiguration) {
        config.title = 'AAA Flag & Banner';

        config.map([
            // { route: 'login', name: 'login', moduleId: PLATFORM.moduleName('login'), title: 'Login' },
            { route: ['login'], name: 'login', moduleId: PLATFORM.moduleName('login'), nav: false, title: 'Login', settings: { auth: false, root: 'public' } },
            { route: ['login/token/:token'], name: 'loginWithToken', moduleId: PLATFORM.moduleName('login'), nav: false, title: 'Login', settings: { auth: false, root: 'public' } },
            { route: ['login/*redirect'], name: 'login', moduleId: PLATFORM.moduleName('login'), nav: false, title: 'Login', settings: { auth: false, root: 'public' } },
            { route: ['register'], name: 'register', moduleId: PLATFORM.moduleName('register'), nav: false, title: 'Register', settings: { auth: false, root: 'public' } },
            { route: ['register/*redirect'], name: 'register', moduleId: PLATFORM.moduleName('register'), nav: false, title: 'Register', settings: { auth: false, root: 'public' } },

            { route: '', name: 'home', moduleId: PLATFORM.moduleName('home'), title: 'Home', settings: { auth: true } },
            // { route: 'cart', name: 'cart-view', moduleId: 'cart-view', title: 'Cart', settings: { auth: true } },
            // { route: 'checkout',            name: 'checkout',      moduleId: 'checkout',      title: 'Checkout',          settings: { auth: true } },
            // { route: 'c/:categoryUrl',  name: 'categories',     moduleId: 'categories',     title: 'Categories',    settings: { auth: true } },
            // { route: 'list',            name: 'list',           moduleId: 'product-list',   title: 'Product List',  settings: { auth: true } }
        ]);
    }

    constructor(public appState: ApplicationState, public eventAggregator: EventAggregator, public router: Router) {
        //console.log('CONSTRUCTOR public');
        // if (this.appState.siteUrl && this.appState.siteUrl !== 'aaa') {
        //     this.router.navigateToRoute('branded-site', { siteUrl: this.appState.siteUrl });
        // }
    }

    activate(params, routeConfig: RouterConfiguration) {

        var auth_token = localStorage.getItem(_WEBPACK_IMPORT_.SESSION_TOKEN_KEY);
        if (typeof auth_token !== "undefined" && auth_token !== null) {

            return this.appState.getProfile(auth_token).then((profile) => {

                if (this.appState.siteUrl === 'aaa') this.router.navigateToRoute('home');
                else this.router.navigate(this.appState.siteUrl);

            }).catch(
                (error) => {
                    this.appState.signOut();
                }
            );

        }

        // var siteUrl = params.siteUrl;
        //console.log('ACTIVATE public');
        // if (self.appState.siteUrl && siteUrl != 'aaa') {
        //     self.router.navigateToRoute('branded-site', { siteUrl: self.appState.siteUrl });
        // }

        //if (this.appState.userProfile) this.appState.getCart();
        //return this.appState.loadSiteConfig(siteUrl);
        // if (!self.appState.sessionToken && routeConfig.title != 'Login') {
        //     //self.router.
        //     self.router.navigateToRoute('login');
        //     //return false;
        // }
        // else {
        //     //this.router.currentInstruction.fragment
        //     return this.appState.loadSiteConfig(siteUrl);
        // }
    }

}


