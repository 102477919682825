import { Router } from 'aurelia-router';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { ApplicationState } from './application-state';
import { Product } from './product';
import { SiteConfig, Category, Item } from './models/site-config';

@autoinject()
export class Categories {

    public items: Map<string, Category>;
    public heading: string;
    public showCategories: boolean = true;
    public category: Category;
    public categories = new Map<string, Category>();
    public assetsRootUrl;

    constructor(public appState: ApplicationState, public router: Router, public eventAggregator: EventAggregator, public dialogService: DialogService) { }

    activate(params, routeConfig) {
        // console.log('params', params);
        return this.appState.getSiteConfig().then(site => {
            // var catUrl: string[] = params.categoryUrl.split('/');
            // console.log(catUrl, catUrl[catUrl.length - 1]);
            // var cat = site.categoriesNotNested.get(catUrl[catUrl.length - 1]);
            var cat = site.categoriesNotNested.get(params.categoryUrl);
            console.log(cat);
            this.categories = site.categories;
            this.category = cat;
            this.items = cat.children;
            this.assetsRootUrl = site.assetsRootUrl;

            ApplicationState.updateSelectedCategory(site.categories, cat.id);
        });
    }

    viewProduct(item) {
        console.log('open product dialog', item);
        this.dialogService.open({ viewModel: Product, model: { item: item, assetsRootUrl: this.assetsRootUrl } });
    }

    viewProductGraphics(item: Category) {
        console.log('open product list', item);
        this.router.navigateToRoute('product-graphics', { productUrl: item.url });
        //this.router.navigateToRoute('product-graphics', { categoryUrl: this.category.url, productUrl: item.url });// .dialogService.open({ viewModel: Product, model: { item: item, assetsRootUrl: this.assetsRootUrl } });
    }
}
