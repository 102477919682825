export class FormatCurrencyValueConverter {
    toView(value, format?) {
        var addComma = function (value: string) {
            var parts = value.split('.');
            // console.log('parts', parts);
            let formatted = '';

            for (var i = 0; i < parts[0].length; i++) {
                if (i == 3) formatted = ',' + formatted;
                formatted = parts[0][parts[0].length - i - 1] + formatted;
            }

            if (parts.length === 1) formatted += '.00';
            else {
                if (parts[1].length === 1) parts[1] += '0';
                else if (parts[1].length === 0) parts[1] += '00';
                formatted += '.' + parts[1];
            }

            return formatted;
        }

        var strValue: string;
        if (typeof value === 'string') strValue = value;
        else strValue = (value || value === 0) ? value.toString(10) : '';

        if (strValue.length === 0) return '';
        return addComma(strValue);
    }

    // fromView(string) {
    //     var value = parseInt(string);
    //     console.log(this, value);
    //     this.toView(value);
    //     return value;
    // }
}
