import { autoinject } from 'aurelia-framework';
import { Redirect, RouterConfiguration, Router } from 'aurelia-router'
import { PLATFORM } from 'aurelia-pal';
import { ApplicationState } from './application-state';
import { EventAggregator } from 'aurelia-event-aggregator';
// import { SiteConfig, Category, Item } from './models/site-config';

@autoinject()
export class BrandedSite {

    siteUrl: string;

    configureRouter(config: RouterConfiguration) {
        config.title = 'AAA Flag & Banner';

        config.map([
            // { route: 'login', name: 'branded-login', moduleId: PLATFORM.moduleName('login'), title: 'Login' },
            { route: ['login'], name: 'branded-login', moduleId: PLATFORM.moduleName('login'), nav: false, title: 'Login', settings: { auth: false, root: 'branded' } },
            { route: ['login/token/:token'], name: 'loginWithToken', moduleId: PLATFORM.moduleName('login'), nav: false, title: 'Login', settings: { auth: false, root: 'branded' } },
            { route: ['login/*redirect'], name: 'login', moduleId: PLATFORM.moduleName('login'), nav: false, title: 'Login', settings: { auth: false, root: 'branded' } },
            { route: ['register'], name: 'register', moduleId: PLATFORM.moduleName('register'), nav: false, title: 'Register', settings: { auth: false, root: 'branded' } },
            { route: ['register/*redirect'], name: 'register', moduleId: PLATFORM.moduleName('register'), nav: false, title: 'Register', settings: { auth: false, root: 'branded' } },

            { route: '', name: 'home', moduleId: PLATFORM.moduleName('branded-home'), title: 'Home', settings: { auth: true } },
            // { route: '', name: 'branded-home', moduleId: PLATFORM.moduleName('branded-home'), title: 'Home', settings: { auth: false } },
            { route: 'cart', name: 'cart-view', moduleId: PLATFORM.moduleName('cart-view'), title: 'Cart', settings: { auth: true } },
            { route: 'checkout', name: 'checkout', moduleId: PLATFORM.moduleName('checkout'), title: 'Checkout', settings: { auth: true } },
            { route: 'c/:categoryUrl', name: 'categories', moduleId: PLATFORM.moduleName('categories'), title: 'Categories', settings: { auth: true } },
            // { route: 'c/:categoryUrl/p/:productUrl',  name: 'product-graphics',     moduleId: 'product-graphics',     title: 'Product',    settings: { auth: true } },
            { route: 'list', name: 'list', moduleId: PLATFORM.moduleName('product-list'), title: 'Product List', settings: { auth: true } }
        ]);
    }

    constructor(
        public appState: ApplicationState,
        public eventAggregator: EventAggregator,
        public router: Router
    ) {
        // console.log('CONSTRUCTOR');
    }

    activate(params, routeConfig: RouterConfiguration) {

        console.log('params', params);
        console.log('routeConfig', routeConfig);
        //var siteUrl = params.siteUrl;
        //this.httpClientConfig.configure();
        //console.log(this.appState);
        //return this.appState.loadSiteConfig('1').then(function(){console.log('finished loading siteConfig 1')});

        let siteConfigPromise = this.appState.getSiteConfig(params.siteUrl).then(siteConfig => {
            // preloading site config
        }).catch(error => {
            this.router.navigate('aaa');
        });

        var auth_token = localStorage.getItem(_WEBPACK_IMPORT_.SESSION_TOKEN_KEY);
        if (typeof auth_token !== "undefined" && auth_token !== null) {

            return this.appState.getProfile(auth_token).then((profile) => {
                return siteConfigPromise;
                // if (profile) {
                //     return this.appState.getSiteConfig(this.appState.siteUrl).then(siteConfig => {
                //         if (siteConfig.url && siteConfig.url !== params.siteUrl) {
                //             if (siteConfig.url === 'aaa') this.router.navigateToRoute('aaa');
                //             else this.router.navigateToRoute('branded-site', { siteUrl: siteConfig.url });
                //         }
                //     });
                // }
            }).catch(
                (error) => {
                    console.error('App - activate()', error);
                    this.appState.signOut();
                    // localStorage.removeItem(_WEBPACK_IMPORT_.SESSION_TOKEN_KEY);
                    // this.router.navigateToRoute('aaa');
                }
            );

        }
        // else if (params.siteUrl && params.siteUrl !== 'aaa') {

        return siteConfigPromise;

        // } else {

        //     let siteUrl = localStorage.getItem('portal_site');
        //     if (siteUrl && siteUrl !== 'aaa') {
        //         return this.appState.getSiteConfig(siteUrl).then(siteConfig => {
        //             this.router.navigateToRoute('branded-site', { siteUrl });
        //         }).catch(error => {
        //             localStorage.removeItem('portal_site');
        //             this.router.navigateToRoute('aaa');
        //         });
        //     }

        // }

    }

    // activate(params, routeConfig: RouterConfiguration) {
    //     this.siteUrl = params.siteUrl;
    //     this.appState.siteUrl = params.siteUrl;
    //     // if (!this.appState.siteConfig.isResolved && !this.appState.siteConfig.isPending) return this.appState.loadSiteConfig(this.siteUrl);
    //     // else if (this.appState.siteConfig.isRejected) throw 'Failed to load site configuration';

    //     // console.log('ACTIVATE', routeConfig);
    //     //if (this.appState.userProfile) this.appState.getCart();

    //     //return this.appState.getCart();

    //     // if (!self.appState.sessionToken && routeConfig.title != 'Login') {
    //     //     //self.router.
    //     //     self.router.navigateToRoute('login');
    //     //     //return false;
    //     // }
    //     // else {
    //     //     //this.router.currentInstruction.fragment
    //     //     return this.appState.loadSiteConfig(siteUrl);
    //     // }
    // }

    // attached() {
    //     return this.appState.getSiteConfig(this.siteUrl)
    //         .then((site) => { if (this.appState.userProfile && this.appState.userProfile.token) this.appState.getCart() });

    //     //console.log('siteConfig promise', this.appState.siteConfig.promise.isResolved());
    //     // if (!this.appState.siteConfig.promise.isResolved()) { // && !this.appState.siteConfig.isPending
    //     //     return this.appState.loadSiteConfig(this.siteUrl)
    //     //         .then(() => { console.log(this.appState.userProfile); if (this.appState.userProfile && this.appState.userProfile.token) this.appState.getCart() });
    //     // }
    //     // else if (this.appState.siteConfig.promise.isPending()) {
    //     //     console.log('siteConfig is pending??');
    //     // }
    //     // else {
    //     //     this.appState.getCart();
    //     // }
    // }

}


