import { Router, RouterConfiguration } from 'aurelia-router';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { PLATFORM } from 'aurelia-pal';
import { DialogService } from 'aurelia-dialog';
import { ApplicationState } from './application-state';
import { Product } from './product';
import { SiteConfig, Category, Item } from './models/site-config';

@autoinject()
export class Categories {

    public items = [];
    public heading: string;
    public showCategories: boolean = true;
    public category: Category;
    public categories = new Map<string, Category>();
    public assetsRootUrl;

    configureRouter(config: RouterConfiguration, router: Router) {
        config.map([
            { route: '', name: 'category', moduleId: PLATFORM.moduleName('category'), nav: false, title: 'Category' },
            { route: 'p/:productUrl', name: 'product-graphics', moduleId: PLATFORM.moduleName('product-graphics'), nav: false, title: 'Product' }
        ]);

        this.router = router;
    }

    constructor(public appState: ApplicationState, public router: Router, public eventAggregator: EventAggregator, public dialogService: DialogService) { }

    activate(params, routeConfig) {
        // console.log('params', params);
        return this.appState.getSiteConfig().then(site => {
            this.categories = site.categories;
            // var cat = site.categoriesNotNested.get(params.categoryUrl);
            // if (params.productUrl) {
            //     var product = site.categoriesNotNested.get(params.productUrl);
            //     ApplicationState.updateSelectedCategory(site.categories, product.id);
            // } else {
            //     ApplicationState.updateSelectedCategory(site.categories, cat.id);
            // }
            //self.category = cat;
            //self.items = cat.items;
            //self.assetsRootUrl = site.assetsRootUrl;
        });
    }

    viewProduct(item) {
        console.log('open product dialog', item);
        this.dialogService.open({ viewModel: Product, model: { item: item, assetsRootUrl: this.assetsRootUrl } });
    }
}
