import { Router } from 'aurelia-router';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { ApplicationState } from './application-state';
import { Product } from './product';
import { SiteConfig, Category, Item } from './models/site-config';
import { Netsuite } from './services/netsuite-service';
import { Cart, CartLine, CartItem } from './models/cart';
import { ProductImage } from './product-image';

@autoinject()
export class ProductGraphics {

    @bindable allSelected: boolean = true;
    @bindable addToCartBtn: HTMLButtonElement;

    category: Category;
    assetsRootUrl;

    constructor(public appState: ApplicationState, public router: Router, public eventAggregator: EventAggregator, public netsuite: Netsuite, public dialogService: DialogService) {
    }

    activate(params, routeConfig) {
        // console.log('params', params);
        return this.appState.getSiteConfig().then(site => {
            // this.item = site.items.find(i => i.url == params.productUrl);
            this.category = site.categoriesNotNested.get(params.productUrl);
            //Creating clone of versions
            //this.itemVersions = JSON.parse(JSON.stringify(this.item.versions));
            //console.log(this.item);
            this.assetsRootUrl = site.assetsRootUrl;

            // var cat = site.categoriesNotNested.get(params.categoryUrl);
            // if (params.productUrl) {
            var product = site.categoriesNotNested.get(params.productUrl);
            ApplicationState.updateSelectedCategory(site.categories, product.id);
            // } else {
            //     ApplicationState.updateSelectedCategory(site.categories, cat.id);
            // }
        });
    }

    addToCart() {

        this.addToCartBtn.disabled = true;
        //var _window: Window = window;
        window.document.body.style.cursor = 'wait';
        var cartLine = new CartLine(this.category);
        // cartLine.category = this.category;
        // cartLine.description = this.category.name;
        //cartLine.price = this.item.price.toFixed(2);

        // var hardwareQty = 0;
        this.category.items.forEach(item => {
            if (item.selected && item.quantity) {
                // var cartLine: CartLine;
                // cartLines.find(l => l.);
                // Creating clone of version
                // var itemVersion = JSON.parse(JSON.stringify(ver));
                var itemVersion = new CartItem(item, item.quantity);
                // var verQty = Number(ver.quantity);
                // cartLine.quantity += verQty;
                if (item.subitems && item.subitems.length) {
                    // hardwareQty += verQty;
                    item.subitems.forEach(subitem => {
                        if (subitem.selected) itemVersion.subitems.push(new CartItem(subitem, item.quantity));
                    });
                }
                cartLine.items.push(itemVersion);
            }
        });

        this.netsuite.addToCart(cartLine)
            .then((result) => {
                window.document.body.style.cursor = 'default';
                this.addToCartBtn.disabled = false;
                //console.log('addToCart', result);
                if (result.success) {
                    this.appState.loadCart(result.cart);
                    this.category.items.forEach(ver => { ver.selected = true; ver.quantity = 1; });
                    this.router.navigateToRoute('cart-view');
                    // this.appState.itemCount += cartLine.quantity;
                    // this.appState._cart.lines.push(cartLine);
                }
            });

    }

    allSelectedChanged(selected: boolean) {
        this.category.items.forEach(ver => {
            ver.selected = selected;
        });
    }

    viewProductImage(item) {
        //console.log('open product dialog', ver);
        this.dialogService.open({
            viewModel: ProductImage, //'product-image',
            model: {
                item: item,
                assetsRootUrl: this.assetsRootUrl
            },
            lock: false
        });
    }

    // viewProductGraphics(item: InventoryItem) {
    //     console.log('open product list', item);
    //     this.router.navigateToRoute('product-graphics', { categoryUrl: this.category.url, productUrl: item.url });// .dialogService.open({ viewModel: Product, model: { item: item, assetsRootUrl: this.assetsRootUrl } });
    // }
}
