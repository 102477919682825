export class DeferredPromise<T> {
    promise: Promise<T>;
    resolve: (T) => Promise<T>;
    reject: (T) => Promise<T>;
    pending = true;
    resolved = false;
    rejected = false;
    started = false;

    constructor(func?: () => T) {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = (result: any) => {
                // console.log('deferred promise resolved', result);
                resolve(result);
                this.pending = false;
                this.resolved = true;
                return this.promise;
            }
            this.reject = (result: any) => {
                console.warn('deferred promise rejected', result);
                reject(result);
                this.pending = false;
                this.rejected = true;
                return this.promise;
            };
        });
        if (func) this.start(func);
    }

    start(func: () => T): T {
        this.started = true;
        var t = func();
        this.resolve(t);
        return t;
    }

    startAsync(func: () => Promise<T>): Promise<T> {
        this.started = true;
        func().then((t) => this.resolve(t));
        return this.promise;
    }
}
